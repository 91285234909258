import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "raiz",
    component: () => import("../views/rde.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/rde",
    name: "rde",
    component: () => import("../views/rde.vue"),   
    meta:{ ispublic:true}
  },
  {
    path: "/rda",
    name: "rda",
    component: () => import("../views/rda.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/dashboard/login",
    name: "login",
    component: () => import("../views/dashboard/login.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/cartarda",
    name: "cartarda",
    component: () => import("../views/cartacomplejo.vue"),
    props: { tituloHeader: "Rincon Gourmet", idcomplejo: 1, complejo: 'rda', menu: 1, colorMenu:'#2E7D32', moneda:'$', piegeneral:'' },
    meta:{ ispublic:true}
  },
  {
    path: "/cartarde",
    name: "cartarde",
    component: () => import("../views/cartacomplejo.vue"),
    props: {  tituloHeader: "Rincon Gourmet", idcomplejo: 2, complejo: 'rde', menu: 2, colorMenu:'#002A53', moneda:'u$', piegeneral: "Descuento Especial 20% off a Socios de Rincón Club<br>Recupero del IVA! 9% off - Pagando con Tarjeta de Crédito o débito para turistas residentes.<br>"},
    meta:{ ispublic:true}
  },
  {
    path: "/roomservicerda",
    name: "roomservicerda",
    component: () => import("../views/cartacomplejo.vue"),
    props: {tituloHeader: "Room Service", idcomplejo: 1, complejo: 'rda', menu: 3, colorMenu:'#2E7D32', moneda:'$', piegeneral:'Horario de 11 a 15 hs y de 20 a 23.30 hs | Pedidos al interno 128 (1 hora de antelación)'},
    meta:{ ispublic:true}
  },
  {
    path: "/reservarmesarde",
    name: "reservarmesarde",
    component: () => import("../views/redirigeWhatsApp.vue"),
    props:{ numero: '59895861845',texto:'Me gustaría reservar mesa para el restaurante' },
    meta:{ ispublic:true}
  },
  {
    path: "/roomservicerde",
    name: "roomservicerde",
    component: () => import("../views/redirigeWhatsApp.vue"),
    props:{ numero: '59895861845',texto:'Me gustaría saber el Menú del día' },
    meta:{ ispublic:true}
  },
  {
    path: "/normasrde",
    name: "normasrde",
    component: () => import("../views/normascomplejo.vue"),
    props: { tituloHeader: 'Normas del Complejo', idcomplejo:2, complejo:'rde', colorMenu:'#002A53'},
    meta:{ ispublic:true}
  },
  {
    path: "/normasrda",
    name: "normasrda",
    component: () => import("../views/normascomplejo.vue"),
    props: { tituloHeader: 'Normas del Complejo', idcomplejo:1, complejo:'rda', colorMenu:'#2E7D32'},
    meta:{ ispublic:true}
  },
  {
    path: "/telefonosrde",
    name: "telefonosrde",
    component: () => import("../components/telefonosrde.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/telefonosrda",
    name: "telefonosrda",
    component: () => import("../components/telefonosrda.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/serviciosrde",
    name: "serviciosrde",
    component: () => import("../components/serviciosrde.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/serviciosrda",
    name: "serviciosrda",
    component: () => import("../components/serviciosrda.vue"),
    meta:{ ispublic:true}
  },
  {
    path: "/bbqrda",
    name: "bbqrda",
    component: () => import("../views/cartacomplejo.vue"),
    props: { tituloHeader: "Carta Mediodía", idcomplejo: 1, complejo: 'rda', menu: 4, colorMenu:'#2E7D32', moneda:'$', piegeneral:'MEDIODIA 12:00 A 14:30PM' },
    meta:{ ispublic:true}
  },
  {
    path: "/actividadesrde",
    name: "actividadesrde",
    component: () => import("../views/redirigeWhatsApp.vue"),
    props: { numero:'59895981130', texto: 'Me gustaría saber las actividades recreativas del día' },
    meta:{ ispublic:true}
  },
  {
    path: "/actividadesrda",
    name: "actividadesrda",
    component: () => import("../components/actividadesrda.vue"),
    props: { tituloHeader: "Activididades recreativas", idcomplejo: 1, complejo: 'rda',  colorMenu:'#2E7D32' },    
    meta:{ ispublic:true}
  },
  {
    path: "/actividadesrdaWhatsapp",
    name: "actividadesrdaWhatsapp",
    component: () => import("../views/redirigeWhatsApp.vue"), 
    props: { numero:'5491151117247', texto: '' },
    meta:{ ispublic:true}
  },
  {
    path: "/inventariorda",
    name: "inventariorda",
    component: () => import("../views/inventariorda.vue"),
    props: { tituloHeader: "Inventario de Habitaciones", idcomplejo: 1, complejo: 'rda', colorMenu:'#2E7D32', moneda:'$' },
    meta:{ ispublic:true}
  },

  {
    path: "/dashboard/rdaadmin",
    name: "rdaAdmin", 
   component: () => import("../views/dashboard/rdaAdmin.vue"),
   meta: { auth:'true'}
   },
   {
    path: "/dashboard/rdeadmin",
    name: "rdeAdmin", 
   component: () => import("../views/dashboard/rdeAdmin.vue"),
   meta: { auth:'true'}
   },
  {
    path: "/dashboard/cartardaadmin",
    name: "cartardaAdmin", 
   component: () => import("../views/dashboard/cartaAdmin.vue"),
   props: {tituloHeader:"Rincon Gourmet", complejo: 'rdaAdmin', idcomplejo:1, idmenu:1, colorMenu:'#2E7D32', moneda:'$'},
   meta: { auth:'true'}
   },
  {
    path: "/dashboard/cartardeadmin",
    name: "cartardeAdmin",
    component: () => import("../views/dashboard/cartaAdmin.vue"),
   props: {tituloHeader:"Rincon Gourmet", complejo: 'rdeAdmin', idcomplejo:2, idmenu:2, colorMenu:'#002A53', moneda:'u$'},
   meta: { auth:'true'}
   },
   {
    path: "/dashboard/bbqrdaadmin",
    name: "bbqrdaAdmin",
    component: () => import("../views/dashboard/cartaAdmin.vue"),
   props: {tituloHeader:"Rincon Gourmet", complejo: 'rdaAdmin', idcomplejo:1, idmenu:4, colorMenu:'#2E7D32', moneda:'$'},
   meta: { auth:'true'}
   },
   {
    path: "/dashboard/roomservicerdaadmin",
    name: "roomservicerdaAdmin",
    component: () => import("../views/dashboard/cartaAdmin.vue"),
   props: {tituloHeader:"Room Service", complejo: 'rdaAdmin', idcomplejo:1, idmenu:3, colorMenu:'#2E7D32', moneda:'$'},
   meta: { auth:'true'}
   },
   {
   path: "/dashboard/normasrdaadmin",
    name: "normasrdaAdmin",
    component: () => import("../views/dashboard/normasAdmin.vue"),
   props: {tituloHeader:"Normas del complejo", complejo: 'rdaAdmin', idcomplejo:1, colorMenu:'#2E7D32'},
   meta: { auth:'true'}
   },
   {
    path: "/dashboard/normasrdeadmin",
     name: "normasrdeAdmin",
     component: () => import("../views/dashboard/normasAdmin.vue"),
    props: {tituloHeader:"Normas del complejo", complejo: 'rdeAdmin', idcomplejo:2, colorMenu:'#002A53'},
    meta: { auth:'true'}
    },
    {
      path: "/dashboard/actividadesrdaadmin",
       name: "actividadesrdaAdmin",
       component: () => import("../views/dashboard/actividadesAdmin.vue"),
      props: {tituloHeader:"Actividades del complejo", complejo: 'rdaAdmin', idcomplejo:1, colorMenu:'#2E7D32'},
      meta: { auth:'true'}
      },
      {
        path: "/dashboard/inventariordaadmin",
        name: "inventariordaAdmin",
        component: () => import("../views/dashboard/inventarioAdmin.vue"),
       props: {tituloHeader:"Inventario de Habitaciones", complejo: 'rdaAdmin', idcomplejo:1,  colorMenu:'#2E7D32'},
       meta:{ ispublic:true}
       // meta: { auth:'true'}
       }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {  // redirige a login si requiere autentificacion
  let esrutapublica = (to.meta.ispublic)
  //alert(JSON.stringify(to))  
  if (esrutapublica) {
    //
      } 
      else{
             if (to.meta.auth==='true' && store.state.autenticado==='false'){
                return next("/dashboard/login")
             }                     
  }
  next();
});

export default router;
