<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    //  home
  },

  data: () => ({}),
  created: function() {
     
    if (this.$route.query.q == "login") {
        this.$router.replace({ path:"/dashboard/login/"})
   }
  }
};
</script>
<style scoped>
.v-application {
  font-family: Monserrat, sans-serif !important;
}
</style>
