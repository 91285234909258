import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
                                state: {
                                        //relacionados con usuario
                                        autenticado:'false',
                                        userId:'',
                                        userName:'',
                                        token:'',
                                        headers:'',
                                        //relacionados con interface
                                        mini:false,
                                        layout:'login-layout',
                                        snackbar:{
                                                  visible:false,
                                                  text: null,
                                                  timeout: 6000,
                                                  multiline: false
                                        }
                                },
                                getters:{
                                         token(state){
                                                      return state.token
                                         },
                                         // userName : state => state.userName
                                         userName(state){
                                                        return state.userName
                                         },
                                         autenticado(state){
                                                            return state.autenticado
                                         },
                                         headers(state){
                                                return state.headers
                                        },
                                        //interface
                                        mini(state){
                                                return state.mini
                                        },
                                        layout(state){
                                                return state.layout
                                        }                             
                                },
                                mutations: {
                                          logueado(state, payload){
                                                                     state.autenticado='true',
                                                                     state.token=payload.token,
                                                                     state.userName=payload.userName,
                                                                     state.userId=payload.userId,
                                                                     state.headers='Bearer' + ' ' + payload.token

                                          },
                                          deslogueado(state){
                                                                    state.autenticado='false',
                                                                    state.token='',
                                                                    state.userId='',
                                                                    state.userName='',
                                                                    state.headers=''
                                          },
                                        //relacionados con interface
                                          grabarLayout(state,payload){
                                                                      state.layout=payload
                                          },
                                          grabarMini(state,payload){
                                                                    state.mini=payload
                                          },
                                          mostrarSnackbar(state, payload) {
                                                                           state.snackbar.text = payload.text
                                                                           state.snackbar.multiline = (payload.text.length > 50) ? true : false                                                
                                                                           if (payload.multiline) {
                                                                               state.snackbar.multiline = payload.multiline
                                                                           }                                                
                                                                           if (payload.timeout) {
                                                                                state.snackbar.timeout = payload.timeout
                                                                           }                                          
                                                                           state.snackbar.visible = true
                                          },
                                          cerrarSnackbar(state) {
                                                                state.snackbar.visible = false
                                                                state.snackbar.multiline = false
                                                                state.snackbar.timeout = 6000
                                                                state.snackbar.text = null
                                          },
                                },
                                actions: {
                                          login: ({commit},payload)=>{                                                             
                                                              commit ('logueado',payload)
                                                             
                                          },
                                          logout:({commit})=>{                                                             
                                                              commit ('deslogueado')
                                                  },
                                },
                                modules: {
                                }
})